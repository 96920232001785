<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Bildirim Gönder</template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-send " class="p-button-sm p-mr-2 p-mb-2 yeni" label="SMS Gönder" @click="_smsGonder()" />
          <Button icon="pi pi-envelope" class="p-button-sm p-mr-2 p-mb-2 yeni" label="Mail Gönder"
            @click="_mailGonder()" />
        </div>
        <DataTable_Custom v-if="DataTable_render" :columns="columns" :data="BildirimListesi"
          v-on:set-child-data="getDataChild" v-on:selected_rows="getSelectedRows" :selectionmode="true"
          :detailbutton="false" datakey="rowid" />
      </template>
    </Card>
  </div>


</template>

<script>
import DataTable_Custom from "../DataTable/DataTable.vue";
import GlobalServis from "../../../services/GlobalServis";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      DataTable_render: true,
      BildirimListesi: [],
      selectedBildirim: [],
      columns: [
        { field: "Unvan", header: "Ünvan" },
        { field: "Donem", header: "Dönem" },
        { field: "Toplam", header: "Toplam Tutar" },
      ],
    };
  },
  methods: {
    getSelectedRows(SelectedRows) {
      this.selectedBildirim = SelectedRows;
    },
    resetForm() {
      // this.v$.$reset;
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      this._GetBildirimTablosu();

    },
    _GetBildirimTablosu() {
      GlobalServis.GlobalServis("GET", "GetBildirimGonderTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.BildirimListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },

    _smsGonder() {
      this.selectedBildirim.forEach(element => {
        setInterval(() => { }, 500);
        let data = {
          // Bildirim_rowid: element.Bildirim_rowid,
          Donem: element.Donem,
          pMukellef: element.Mukellef_rowid,
          Bildirim_tur: 'SMS'
        }
        GlobalServis.GlobalServis("POST", "BildirimGonder", data).then(
          (res) => {
            if (res.status == 200) {
              this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Sms gönderim isteği iletildi.",
                  life: 3000,
                });

            } else if (res.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Önemli bir hata meydana geldi",
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Bilinmeyen bir hata meydana geldi",
                life: 3000,
              });
            }
          }
        );
      });
    },
    _mailGonder() {
      this.selectedBildirim.forEach(element => {
        setInterval(() => { }, 500);
        let data = {
          // Bildirim_rowid: element.Bildirim_rowid,
          Donem: element.Donem,
          pMukellef: element.Mukellef_rowid,
          Bildirim_tur: 'MAIL'
        }
        GlobalServis.GlobalServis("POST", "BildirimGonder", data).then(
          (res) => {
            if (res.status == 200) {
              this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Mail gönderim isteği iletildi.",
                  life: 3000,
                });
              

            } else if (res.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Bir hata meydana geldi",
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Hata",
                detail: "Bilinmeyen bir hata meydana geldi",
                life: 3000,
              });
            }
          }
        );
      });
    }
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable_Custom,
  },
  validations() {
    return {
      BildirimTarih: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      Tutar: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
